import request from '@/axios/request';
import qs from "qs";

export function addConfig(data) {
    return request({
      url: '/api/config/addConfig',
      method: 'post',
      data: qs.stringify(data, { arrayFormat: 'repeat', skipNulls: true })
    });
}
export function getConfig() {
  return request({
    url: '/api/config/getConfig',
    method: 'get'
  });
}