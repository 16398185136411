
<template>
  <el-form ref="form" :model="form" label-width="80px">
    <el-form-item label="QQ">
      <el-input v-model="form.qq"></el-input>
    </el-form-item>
    <el-form-item label="备案号">
      <el-input v-model="form.beian"></el-input>
    </el-form-item>
    <el-form-item label="微信">
      <el-upload
        class="avatar-uploader"
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
      >
        <img v-if="form.wx" :src="form.wx" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        <i @click="ondelete" v-if="form.wx" class="el-icon-plus el-icon-circle-close"></i>
      </el-upload>
    </el-form-item>
    <el-form-item label="七牛配置">
      <el-input type="textarea" class="qiniu" v-model="form.qiniu"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="onSubmit">提交</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { addConfig } from "@/api/config";
import { uploads } from "@/utils/baseCodes";
import { qiniudnUploader } from "@/utils/qiniudnUploader";
import { token, deleteYunImg } from "@/api/upload";
export default {
  data() {
    return {
      form: this.$store.state.config,
      qiniu:this.$store.state.qiniu,
    };
  },
  mounted() {
     this.randomString(10)
  },
  methods: {
    onSubmit() {
      addConfig(this.form).then((res) => {
        if (res.status === 200) {
          this.$store.commit("setConfig", this.form);
          this.$store.commit("setQiniu", this.qiniu);
        }
      });
    },
    ondelete() {
      event.stopPropagation();
      let colds = this.form.wx.split("/");
      console.log(this.qiniu.name)
      deleteYunImg({
        bucket: this.qiniu.name,
        key: colds[colds.length - 1],
      }).then((res) => {
        this.form.wx = ''
        this.onSubmit()
      });
    },
    beforeAvatarUpload(item) {
      const _this = this;
      uploads(item, (file) => {
        token().then((res) => {
          qiniudnUploader(res.data, file, "IMG", (res) => {
            console.log(res);
            this.form.wx = "http://" + _this.qiniu.qiniuaddr + "/" + res.key;
            _this.$store.commit("setConfig", _this.form);
          });
        });
      });
    },
    randomString(code) {
      let len = code
      let $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz123456789' 
      let maxLen = $chars.length
      let pwd = ''
      for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxLen ))
      }
      console.log(pwd)
      return pwd
    }
  },
};
</script>

<style lang="less">
.el-upload.el-upload--text {
  width: 202px;
  height: 202px;
  img {
    width: 100%;
    height: 100%;
  }
}
.el-icon-circle-close {
  font-size: 30px;
  position: absolute;
  top: 5px;
  right: 5px;
  color: red;
  z-index: 9;
}
</style>>


